// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { entryContent } from '../queries'; // eslint-disable-line

// Import Components
import CmsBlocks from '../../../app/components/CmsBlocks';
import LayoutMarketing from '../../../app/components/LayoutMarketing';

const Page = ({
  data: {
    craft: { entry },
  },
  pageContext: { globals },
  ...props
}) => (
  <LayoutMarketing
    hero={{ ...((entry?.richContentHero && entry?.richContentHero[0]) || {}), simple: true }}
    footerLinks={globals?.footer?.footer}
    logo={globals?.general?.logo?.url}
    navItems={globals?.general?.navigationLinks}
    loginUrl={globals?.general?.loginUrl}
    getStartedUrl={globals?.general?.getStartedUrl}
    title={entry?.title}
    {...props}
  >
    <CmsBlocks blocks={entry?.contentBlock} />
  </LayoutMarketing>
);

Page.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  query EntryByUri($uri: String!) {
    craft {
      entry(uri: $uri) {
        ...richContent
      }
    }
  }
`;

export default Page;
